
import { defineComponent } from "vue";
import Swiper from "./modules/swiper.vue";
import SubNav from "./modules/subNav.vue";
import LongBox from "./modules/longBoxa.vue";
import MiddleBox from "./modules/middleBox.vue";
import Message from "@/components/message/Message.vue";

export default defineComponent({
  name: "home",
  components: {
    Swiper,
    SubNav,
    LongBox,
    MiddleBox,
    Message,
  },
  data() {
    return {
    };
  },
});
